<template>
	<div>
		<s-crud
			title="Agentes"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			@EditEvent="clickEditItem($event)"
			@addEvent="addEvent()"
		>
			<template scope="props">
				<v-col>
					<v-row>
						<v-col class="s-col-form">
							<s-toolbar-person
								label="Encargado"
								noEdit
                                @returnPerson="hadReturnPerson($event)"
                            />
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Trabajador"
								readonly
								v-model="NtpFullName"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Email"
								v-model="props.item.AgeEmail"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Telefono Corporativo"
								number
								:min="0"
								v-model="props.item.AgePhoneNumber"
							>
							</s-text>
						</v-col>
					</v-row>
				</v-col>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sAgentService from "@/services/HelpDesk/HdkAgentService.js";

	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

	export default {
		components: {
			sToolbarPerson,
		},

		data() {
			return {
				filter : {},
				config: {
					model : {
						AgeID : "ID",
						TypeStatus : "number",
						TypeStatusName : "string"
					},
					service : _sAgentService,
					headers : [
						{text : "ID", value : "AgeID"},
						{text : "Trabajador", value : "NtpFullName"},
						{text : "Email", value : "AgeEmail"},
						{text : "Telefono", value : "AgePhoneNumber"},
					]
				},
				NtpFullName : "",
				worker : {}
			}
		},

		methods: {
			hadReturnPerson(value) {
				if(value != null)
				{
					this.NtpFullName = value.NtpFullName;
					this.worker = value;
				}
			},

			validationForm(item)
			{
				var errors = {
					error : 0,
					message : ""
				}

				if(item.PrsID == 0){
					errors.error = 1;
					errors.message = "Ingrese trabajador";
				}

				if(item.AgeEmail == "")
				{
					errors.error = 1;
					errors.message = "Ingrese Email"
				}
				console.log('itemitemitem ', item);
				if(item.AgePhoneNumber == "" || item.AgePhoneNumber == undefined)
				{
					errors.error = 1;
					errors.message = "Ingrese número de telefono"
				}

				if(item.AgePhoneNumber != undefined && item.AgePhoneNumber.length < 9)
				{
					errors.error = 1;
					errors.message = "Número de telefono invalido"
				}

				return errors;
			},

			save(item)
			{
				let errors = this.validationForm(item);
				if(errors.error > 0)
				{
					this.$fun.alert(errors.message, "warning");
					return;
				}

				item.PrsID = this.worker.PrsID;
				item.NtpID = this.worker.NtpID;
				item.WkrID = this.worker.WkrID;
				item.CtrID = this.worker.CtrID;
				item.PrsDocumentNumber = this.worker.PrsDocumentNumber;

				console.log('savee ', item, this.worker);
				item.save();
				this.NtpFullName = "";
				this.worker = {};
			},

			clickEditItem(item)
			{
				if(item.length > 0)
				{
					this.NtpFullName = item[0].NtpName;
				}
			},

			addEvent()
			{
				this.NtpFullName = "";
			}
		},
	}
</script>
